import { createRoot } from "react-dom/client";
import "react-simple-toasts/dist/style.css";
import "react-simple-toasts/dist/theme/dark.css";

import { getHashedInvitationID } from "./lib/getHashedInvitationID";
import { getClientID } from "./lib/getClientID";

import * as Sentry from "@sentry/react";
import { Core } from "./core/core";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        beforeStartSpan: (context) => {
          return {
            ...context,
            name: location.pathname.replace(/\/.*/g, "/<hash>"),
          };
        },
      }),
    ],
    tracePropagationTargets: ["video.warmspace.io", "video.staging.warmspace.io", "localhost"],
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || "0.1"),
    environment: process.env.NODE_ENV,
    release: "warmspace-session-ui@" + "v1.9.1-staging",
  });
}

if (process.env.NODE_ENV === "production") {
  console.debug = function () {};
}

// Remove any existing app (really only relevant in development when there is hot-relaoding)
let appElement = document.getElementById("app");
appElement?.remove();

// Create a new app element
appElement = document.createElement("div");
appElement.setAttribute("id", "app");
document.body.appendChild(appElement);

const root = createRoot(appElement, {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn("Uncaught error", error, errorInfo.componentStack);
  }),
  onCaughtError: Sentry.reactErrorHandler(),
  onRecoverableError: Sentry.reactErrorHandler(),
});
const clientID = getClientID();
const hashedInvitationID = getHashedInvitationID();

// Boot the core.
(async () => {
  await Core.Boot(root, clientID, hashedInvitationID);
})();
